import React, { useEffect } from "react";
import { useParams } from "react-router";

const ChartTokenTx = ({ transactions, price, address }) => {
  console.log("Received transactions prop:", transactions);
  console.log("Address: ", address);

  let { contract } = useParams();

  useEffect(() => {
    const header = document.querySelector(".chart-table-header");
    const handleScroll = () => {
      if (window.scrollY > header.offsetTop) {
        header.classList.add("fixed-header");
      } else {
        header.classList.remove("fixed-header");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function renderTransactions(list, animation) {
    if (!list || list.length === 0) {
      return <p>No transactions found</p>;
    }

    return list.map((tx, index) => {
      console.log("Rendering transaction:", tx);

      let isSelling = tx.type === "Sell";
      let phxValue = tx.phxValue;
      let tokenValue = tx.tokenValue;
      let isLastRow = index === list.length - 1;
      let animationText = animation
        ? !isSelling
          ? "bg-move-buy"
          : "bg-move-sell"
        : "";
        return (
          <tr
            key={index}
            className={`chart-table-body ${!isSelling ? "buy" : "sell"} ${animationText} ${
              isLastRow ? "last-row" : ""
            }`}
        >
          <td>{tx.type}</td>
          <td>{phxValue.toLocaleString()}</td>
          <td>{tokenValue.toLocaleString()}</td>
          <td>
            {new Intl.DateTimeFormat("en-US", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(new Date(tx.timeStamp * 1000))}
          </td>
          <td>
            <a
              href={`https://phoenixplorer.com/tx/${tx.hash}/token-transfers`}
              target="_blank"
              rel="noreferrer"
            >
              View Tx
            </a>
            {console.log(
              `https://phoenixplorer.com/tx/${tx.hash}/token-transfers`
            )}
          </td>
        </tr>
      );
    }).filter(Boolean); // Filter out any null items after mapping
  }

  return (
    <table className="chart-table-info">
      <thead>
        <tr className="chart-table-header">
          <th>Type</th>
          <th>PHX Value</th>
          <th>Token Value</th>
          <th>Time</th>
          <th>Tx</th>
        </tr>
      </thead>
      <tbody>{renderTransactions(transactions, true)}</tbody>
    </table>
  );
};

export default ChartTokenTx;