import React from "react";
import { Link } from "react-router-dom";
const GetFeatured = () => {
    return (
        <div className="bg-on-mobile">
            
        </div>
    )
}

export default GetFeatured;