import React, {useState, useEffect} from "react"
import { useParams } from "react-router";
import LightViewChart from '../../components/chart/lightview/chart';
import styles from "./style.css";
import defaultTokenList from './default.tokenList.json';
import Spinner from "../../components/spinner/Spinner";
import FeaturedTokens from "../../components/featured/FeaturedTokens";
import ChartPrice from "../../components/token_chart_dependencies/ChartPrice";
import Search from "../../components/search/Search";
import ChartTag from "../../components/token_chart_dependencies/ChartTag";
import ChartLinks from "../../components/token_chart_dependencies/chart-links/ChartLinks";
import ChtrList from "../../components/token_chart_dependencies/chart_chtr/ChtrList";
import ChartInfoHeader from "../../components/token_chart_dependencies/chart_info_header/ChartInfoHeader";
import ChartInfoBody from "../../components/token_chart_dependencies/chart_info_body/ChartInfoBody";
import GetFeatured from "../../components/getfeatured/GetFeatured";
import twitterLogo from '../../img/twitter.png';
import discordLogo from '../../img/discord.png';
import telegramLogo from '../../img/telegram.png';
import websiteLogo from '../../img/website.png';
import emailLogo from '../../img/email.png';
console.log("Twitter Logo: ", twitterLogo);
console.log("Discord Logo: ", discordLogo);
console.log("Telegram Logo: ", telegramLogo);
console.log("Website Logo: ", websiteLogo);
console.log("Email Logo: ", emailLogo);

const getTokenLogoURI = (tokenName) => {
    const token = defaultTokenList.tokens.find(t => t.name === tokenName);
    return token ? token.logoURI : null;
  };
  const getTokenSocialMedia = (tokenName) => {
    const token = defaultTokenList.tokens.find((t) => t.name === tokenName);
    return token
      ? {
          twitter: token.twitter,
          discord: token.discord,
          telegram: token.telegram,
          website: token.website,
          email: token.email,
        }
      : null;
  };

  const fetchLPPairData = async (tokenAddress) => {
    const lpAddressResponse = await fetch(`https://phxairdrop.ytxviewers.com/api/custom/GetTokenLPAddress?tokenaddress=${tokenAddress}`);
    const lpAddressData = await lpAddressResponse.json();
    
    const pairInfoResponse = await fetch(`http://phoenixplorer.com:3001/api?module=account&action=tokenlist&address=${lpAddressData.lpAddress}`);
    const pairInfo = await pairInfoResponse.json();
  
    // Filter the results to keep only the specific token and 'wPHX'
    const filteredPairInfo = pairInfo.result.filter(token => 
      token.contractAddress.toLowerCase() === tokenAddress.toLowerCase() || token.symbol === 'wPHX'
    );
  
    return {
      lpAddress: lpAddressData.lpAddress,
      pairInfo: filteredPairInfo // Use the filtered list here
    };
  };
  const formatBalance = (balance, decimals) => {
    return (balance / 10 ** decimals).toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 18,
    });
  };

  const TokenChart = ({ fetchTokenData }) => {
    let [token, setToken] = useState({});
    const [latestDataPoint, setLatestDataPoint] = useState(null);
    const [PHXUSDPrice, setPHXUSDPrice] = useState(0);
    let { contract } = useParams();
    const tokenAPool = token.pair && token.pair[0] ? token.pair[0] : null;
    const tokenBPool = token.pair && token.pair[1] ? token.pair[1] : null;
    console.log("Current contract: ", contract);
  
    const handleLatestDataPointChange = (dataPoint) => {
      if(PHXUSDPrice && PHXUSDPrice != 0)
      {
      console.log("Received latest data point:", dataPoint);
      const phxValue = dataPoint.value;
      const usdValue = phxValue * PHXUSDPrice;
      dataPoint.value = usdValue;
      console.log("usdValue: ",usdValue);
      setLatestDataPoint(dataPoint);
      }
    };

    async function fetchUSDValue() {
      try {
        // Replace 'YOUR_API_URL' with the actual URL of the external API
        const response = await fetch('https://api.coinpaprika.com/v1/tickers/phx-phoenix');
        
        // Check if the request was successful (status code 200)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        // Parse the JSON response
        const data = await response.json();
    
        // Extract the USD value
        const usdValue = data.quotes.USD.price;
        setPHXUSDPrice(usdValue);
        // Return the USD value
        //return usdValue;
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    }
 
    useEffect(() => {
      (async () => {
        if (contract) {
          const tokenData = await fetchTokenData(contract);
          const logoURI = getTokenLogoURI(tokenData.name);
          if (logoURI && !tokenData.logo) {
            tokenData.logo = logoURI;
          }
    
          const lpPairData = await fetchLPPairData(tokenData.contractAddress);
          tokenData.lpAddress = lpPairData.lpAddress;
          tokenData.pair = lpPairData.pairInfo;
    
          const socialMediaInfo = getTokenSocialMedia(tokenData.name);
          if (socialMediaInfo) {
            tokenData.twitter = socialMediaInfo.twitter;
            tokenData.discord = socialMediaInfo.discord;
            tokenData.telegram = socialMediaInfo.telegram;
            tokenData.website = socialMediaInfo.website;
            tokenData.email = socialMediaInfo.email;
          }
    
          console.log("Token object: ", tokenData);
          setToken(tokenData);
        }
      })();
      fetchUSDValue();
    }, [contract, fetchTokenData,PHXUSDPrice]);
      console.log("Token object: ", token);
  
    if (!Object.keys(token).length) {
      return (
        <div>
          <section className="chart-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Spinner />
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else {
        console.log("Token object in TokenChart:", token);
        console.log("Token pair data in TokenChart:", token.pair);
      return (
        <div className="d-flex">
            <section className="chart-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <FeaturedTokens chart_featured featured_txt />
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                    <div className="token-info-container">
                    <div className="first-row">
  <div className="token-info">
    <h1>{token.name}</h1>
    <div className="token-details">
      <div className="token-contract">
        <strong>Contract:</strong> {token.contractAddress}
      </div>
      <div className="token-social-media">
      <div>
  {token.twitter && (
    <a href={token.twitter} target="_blank" rel="noopener noreferrer">
      <img src={twitterLogo} alt="Twitter" width="24" height="24" />
    </a>
  )}
  {token.discord && (
    <a href={token.discord} target="_blank" rel="noopener noreferrer">
      <img src={discordLogo} alt="Discord" width="24" height="24" />
    </a>
  )}
  {token.telegram && (
    <a href={token.telegram} target="_blank" rel="noopener noreferrer">
      <img src={telegramLogo} alt="Telegram" width="24" height="24" />
    </a>
  )}
  {token.website && (
    <a href={token.website} target="_blank" rel="noopener noreferrer">
      <img src={websiteLogo} alt="Website" width="24" height="24" />
    </a>
  )}
  {token.email && (
    <a href={token.email} target="_blank" rel="noopener noreferrer">
      <img src={emailLogo} alt="Email" width="24" height="24" />
    </a>
  )}
</div>
      </div>
    </div>
  </div>
  {token.pair &&
    token.pair.pairInfos &&
    token.pair.pairInfos.value > 0 ? (
    <ChartPrice
      token_price={token.pair.pairInfos.value}
      token_movement={token.pair.pairInfos?.variation?.minute}
    />
  ) : null}
  <div>
  </div>
  <div className="desktop-only">
    <Search />
  </div>
</div>
<div className="second-row" style={{ display: "flex", alignItems: "center" }}>
  {token.logo ? (
    <img
      src={token.logo}
      alt={token.name}
      className="token-logo"
      width="48"
      height="48"
    />
  ) : (
    <ChartTag
      token_address={token.contract}
      token_chain={"PHX"}
      logo_found={false}
    />
  )}
{latestDataPoint && (
  <div>
    <span
      className="price" // Use the correct CSS class (without styles)
    >
      Price:$ {latestDataPoint.value.toFixed(8)}
    </span>
  </div>
)}
  <ChartLinks
    btn1_text="Trade"
    token_chain={"PHX"}
    address={token.contractAddress}
  />
  <ChtrList
    symbol={token.symbol}
    address={token.contractAddress}
    chain={"PHX"}
  />
</div>
  <div className="third-row mobile-only">
    <Search />
  </div>
</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="chart-container">
                                <div className="chart-container">
                                <LightViewChart contract={contract} tokenDecimal={token.decimals} onLatestDataPointChange={handleLatestDataPointChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                        <div className="chart-info-container">
                            <ChartInfoHeader tokenName={token.name} tokenSymbol={token.symbol} />
                                {console.log("Token pairs before passing to ChartInfoBody:", token.pair)}
                            <ChartInfoBody
                                transactions={token.txn ? token.txn.transactions || [] : []}
                                pairs={token.pair || []}
                                address={token.contractAddress}
                                price={token.pair && token.pair.pairInfos ? token.pair.pairInfos.value : 0}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mobile-only">
                                <GetFeatured />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <SideNav /> */}
        </div>
    )}
}

export default TokenChart;
