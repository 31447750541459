import explorerPHX from "../img/explorer/phx.svg"



const EnumChainInfos = {
    PHX: {
        id: 13381,
        name: "PHX",
        "dex": "https://fireswap.org/#/swap?outputCurrency=$TOKEN$",
        "chart": "https://poocoin.app/tokens/$TOKEN$",
        "explorer": "https://phoenixplorer.com/token/$TOKEN$",
        "dex_img": explorerPHX,
        "chart_img": explorerPHX,
        "explorer_img": explorerPHX,
        "chain_img": explorerPHX
    },
   
}
export default EnumChainInfos;