import React, { useState, useEffect } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import TokenChart from "./views/chart_detail/index";
import Home from "./views/homepage/Home";
import Layout from './components/layout/Layout';
import './App.css';
import './variables.css';
import './darkVariables.css';
import LightViewChart from './components/chart/lightview/chart';
import ApiToken from './api/token';

const App = () => {
  const [selectedToken, setSelectedToken] = useState(null);
  const [tokenDecimal, setTokenDecimal] = useState(null);

  const handleTokenClick = async (token) => {
    console.log("Selected token in App:", token);
    const tokenData = await ApiToken.getToken(token.contractAddress);
    console.log("Fetched token data in App:", tokenData);
    console.log("Navigating to /chart/lightview/" + token.contractAddress);
    setSelectedToken(tokenData);
  };

  useEffect(() => {
    if (selectedToken) {
      console.log("Token decimal in App.jsx:", selectedToken.decimals);
      setTokenDecimal(selectedToken.decimals);
    }
  }, [selectedToken]);
  console.log("Token decimal in App.jsx:", selectedToken?.decimals);
  console.log("Token decimal in App before return:", tokenDecimal);
  return (
    <div>
      <Layout>
      <Routes>
        <Route path="/" element={<Home handleTokenClick={handleTokenClick} />} />
          <Route
              path="/tokens/:contract"
              element={
          <TokenChart
              tokenDecimal={tokenDecimal}
              fetchTokenData={async (contract, decimals) => {
              const tokenData = await ApiToken.getToken(contract, decimals);
              setTokenDecimal(tokenData.decimals);
              console.log("Token decimal in App.jsx:", tokenDecimal);
            return tokenData;
          }}
        />
      }
    />
      <Route path="/chart/lightview/:contract" element={<LightViewChart tokenDecimal={tokenDecimal} />} />
        </Routes>
        {console.log("Token decimal in ur ass App.jsx:", tokenDecimal)}
      </Layout>
    </div>
  );
};

export default App;