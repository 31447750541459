import axios from "axios";

async function getPrices(address, params) {
  try {
    const response = await axios
      .get(`https://phxairdrop.ytxviewers.com/api/custom/getTokenSwaps?tokenAddress=${address}`, {
        params: params,
      })
      .catch((err) => {
        return err.response;
      })
      .then((res) => {
        if (!res) return { data: [] };
        else return { data: res.data };
      });

    return response;
  } catch (error) {
    throw new Error(`Request error: ${error}`);
  }
}

let ApiChart = {
  getPrices,
};

export default ApiChart;