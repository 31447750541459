import React from "react";
import "./style.css";

const SearchInput = ({ search, setSearch, showResult, onSearchButtonClick, resetKey }) => {
  return (
    <div className="plus-flex">
      <div className="position-relative ">
        <input
          key={resetKey} // Add the key attribute to the input element
          className="width-130"
          type="text"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
          placeholder="Enter token name"
        />
      </div>
    </div>
  );
};

export default SearchInput;
