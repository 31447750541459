import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import PriceManager from "../../../utils/price.manager";
import UtilsNumber from "../../../utils/numbers";
import ApiChart from "../../../api/chart";
import "./style.css";
import {
  Chart as ChartJS,
  LineController,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import streamingPlugin from "chartjs-plugin-streaming";
import ApiToken from '../../../api/token';

ChartJS.register(
  LineController,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Filler,
  zoomPlugin,
  annotationPlugin,
  streamingPlugin
);
let priceManager;

function prepareDataForChart(prices, formatDateTime, tokenDecimal) {
  console.log("Token decimal in prepareDataForChart:", tokenDecimal);
  return prices.map((price) => {
    console.log("Price:", price);
    const timeStamp = parseInt(price.timeStamp);
    const date = new Date(timeStamp * 1000);
    const time = formatDateTime(timeStamp);
    const value = tokenDecimal ? (price.phxValue / price.tokenValue) / Math.pow(10, 18-tokenDecimal) : (price.phxValue / price.tokenValue);
    console.log("Value with tokenDecimal:", value);
    return { time, value };
  });
}

async function retriveDatas(priceManager, formatDateTime, tokenDecimal) {
  let status = await priceManager.getPricesAuto();
  console.log("PriceManager Status:", status);
  if (!status) return;
  console.log("Token decimal in retriveDatas:", tokenDecimal);

  let preparedData = prepareDataForChart(priceManager.prices, formatDateTime,tokenDecimal);
  console.log("preparedData:", preparedData);
  return preparedData;
}

const LightViewChart = ({ contract, onLatestDataPointChange, tokenDecimal }) => {
  const canvasRef = useRef();
  const myRef = useRef();
  const [chartData, setChartData] = useState([]);

  function formatDateTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  }

  const toggleFullscreen = () => {
    if (myRef.current.requestFullscreen) {
      myRef.current.requestFullscreen();
    } else if (myRef.current.mozRequestFullScreen) {
      myRef.current.mozRequestFullScreen();
    } else if (myRef.current.webkitRequestFullscreen) {
      myRef.current.webkitRequestFullscreen();
    } else if (myRef.current.msRequestFullscreen) {
      myRef.current.msRequestFullscreen();
    }
  };

  const takeSnapshot = () => {
    const canvas = document.querySelector(".chart-container canvas");
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "chart_snapshot.png";
    link.click();
  };

  const handleResize = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const container = canvas.parentNode;
  
      if (container) {
        // Calculate the new width and height based on the container's dimensions
        const newWidth = container.clientWidth;
        const newHeight = Math.max(container.clientHeight, 500);
  
        // Set the new width and height of the canvas
        canvas.width = newWidth;
        canvas.height = newHeight;
  
        // Update the chart to fit the new dimensions
        if (chartInstance) {
          chartInstance.resize();
          chartInstance.update("resize");
        }
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiChart.getPrices(contract);
      if (response && response.data) {
          // Process and format the data for the chart
          const formattedData = prepareDataForChart(response.data, formatDateTime, tokenDecimal);
          setChartData(formattedData);
      }
  };

    const priceManager = new PriceManager(contract);
    fetchData();

    (async () => {
      const data = await retriveDatas(priceManager, formatDateTime, tokenDecimal);
      console.log("tokenDecimal in useEffect:", tokenDecimal);
      if (data && data.length) {
        console.log("Formatted data:", data);
        console.log("Latest data point:", data[0]);
        onLatestDataPointChange(data[0]);
      } else {
        myRef.current.innerHTML = "";
      }
    })();
  }, [contract, tokenDecimal]);

  const chartConfig = {
    labels: chartData.map((d) => d.time),
    datasets: [
        {
        label: contract,
        data: chartData.map((d) => d.value),
        borderColor: "rgba(0, 212, 0, 1)",
        backgroundColor: "rgba(0, 88, 0, 0.8)",
        pointRadius: 0,
        fill: 'origin',
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          minUnit: "second",
          maxUnit: "day",
          displayFormats: {
            second: "yyyy-MM-dd HH:mm:ss",
            minute: "yyyy-MM-dd HH:mm",
            hour: "yyyy-MM-dd HH:mm",
            day: "yyyy-MM-dd",
          },
          tooltipFormat: "yyyy-MM-dd HH:mm:ss",
        },
        grid: {
          color: "#f0790a",
          drawOnChartArea: true,
        },
        ticks: {
          display: true,
        },
      },
      y: {
        type: "linear",
        position: "right",
        grid: {
          color: "#a34905",
        },
        color: "#42A5F5",
        ticks: {
          callback: function (value) {
            return "PHX" + value.toFixed(8);
          },
          color: "#0d6f00",
        },
      },
    },
    elements: {
      point: {
        radius: 1,
        hitRadius: 10,
        hoverRadius: 10,
      },
      line: {
        borderWidth: 1,
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
          speed: 10,
          threshold: 10,
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
      },
      annotation: {
        annotations: [
          {
            type: "line",
            mode: "horizontal",
            scaleID: "y",
            value: 0,
            borderColor: "rgba(255, 0, 0, 0.6)",
            borderWidth: 2,
            label: {
              enabled: true,
              content: "Baseline",
              position: "start",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            },
          },
        ],
      },
    },
  };

  return (
    <div className="chart-container" ref={myRef}>
      <Line
        ref={canvasRef}
        data={chartConfig}
        options={chartOptions}
        height={500}
      />
      <div className="toolbar">
          <button className="fullscreen-button" onClick={toggleFullscreen}>⛶</button>
          <button className="snapshot-button" onClick={takeSnapshot}>📷</button>
      </div>
    </div>
  );
};

export default LightViewChart;
