import axios from "axios";

const getTokenListBySearch = async (searchQuery) => {
  try {
    const response = await axios.get(
      `https://phxairdrop.ytxviewers.com/api/custom/gettokenlist?search=${encodeURIComponent}(
        searchQuery
      )}`
    );
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    console.error("Error fetching token list:", error);
    return {
      success: false,
      message: error.message,
    };
  }
};

export default getTokenListBySearch;