import EnumChainId from "./chain.id";

import dexPancake from "../img/dex/pancakeswap.png";
import dexMdex from "../img/dex/mdex.png";
import dexBiswap from "../img/dex/biswap.svg";
import dexBabyswap from "../img/dex/babyswap.svg";
import dexApeswap from "../img/dex/apeswap.png";
import dexAutoshark from "../img/dex/autoshark.png";
import dexDefinix from "../img/dex/definix.png";
import dexSushiswap from "../img/dex/sushiswap.png";
import dexFireswap from "../img/explorer/phx.svg"

const EnumContracts = {
    [EnumChainId.PHX]: {
        ROUTERS: {
            FIRESWAP: "0x8d5567953B0aC3348C959c722D4327f29155AEE4",

        },
        ROUTERS_LOGO: {
            FIRESWAP: dexFireswap,
            MDEX: dexMdex,
            BISWAP: dexBiswap,
            BABYSWAP: dexBabyswap,
            APESWAP: dexApeswap,
            AUTOSHARK: dexAutoshark,
            DEFINIX: dexDefinix,
            SUSHISWAP: dexSushiswap,
        },
        FACTORIES: {
            FIRESWAP: "0xaeA039F542c88Cc14A6Ca38deeeECf91D9B790D6",

        },

    }
};

export default EnumContracts