import React, { useEffect, useState } from "react";
import getTokenListBySearch from "../../api/search";
import SearchInput from "./SearchInput";
import SearchResults from "./SearchResult";
import "./style.css";
import search from "../../api/search";

const parseTokenData = (item) => {
  const tokenData = {
    name: item.tokenName,
    symbol: item.tokenSymbol,
    address: item.tokenAddressHash,
    supply: item.tokenSupply,
    transactionCount: item.transactionCount,
  };
  return tokenData;
};

const Search = ({ isOpenLink, onResultClick }) => {
  const handleSearchButtonClick = () => {
    console.log("Search button clicked!");
  };

  const [search, setSearch] = useState("");
  const [delay, setDelay] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [results, setResults] = useState([]);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    const delayed = setTimeout(() => {
      setDelay(search);
    }, 500);

    return () => {
      clearTimeout(delayed);
    };
  }, [search]);

  useEffect(() => {
    if (delay) setShowResult(true);
    else setShowResult(false);
  }, [delay]);

  const filteredResults = results.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    if (delay) {
      (async () => {
        let res = await getTokenListBySearch(delay);
        if (res && res.success && res.data) {
          const parsedResults = res.data.map((item) => parseTokenData(item));
          console.log("Parsed results: ", parsedResults);
          setResults(parsedResults);
        } else {
          console.log("API Error: ", res);
        }
      })();
    }
  }, [delay]);

  const onTokenSelected = () => {
    setSearch("");
    setResetKey((prevKey) => prevKey + 1);
  };

  return (
    <div className={showResult ? "search c-mr-2 width-190" : "search c-mr-2 width-190"}>
      <SearchInput
        data={results}
        setSearch={setSearch}
        showResult={showResult}
        filteredResults={filteredResults}
        onSearchButtonClick={handleSearchButtonClick}
        resetKey={resetKey}
      />
      <SearchResults
        isOpenLink={isOpenLink}
        setShowResult={setShowResult}
        showResult={showResult}
        results={filteredResults}
        setSearch={setSearch}
        onResultClick={onResultClick}
        onTokenSelected={onTokenSelected}
      />
    </div>
  );
};

export default Search;