import React, { useEffect, useState } from "react";
import EnumChainId from "../../../enum/chain.id";
import UtilsToken from "../../../utils/token";
import './style.css';

const ChartTokenPairs = ({ pairs }) => {
  console.log("Pairs prop data:", pairs);

  const [phxPrice, setPhxPrice] = useState(null);
  const [athValues, setAthValues] = useState({});

  const fetchPhxPrice = async () => {
    try {
      const response = await fetch("https://api.coinpaprika.com/v1/tickers/phx-phoenix");
      const data = await response.json();
      setPhxPrice(data.quotes.USD.price);
    } catch (error) {
      console.error("Error fetching PHX price:", error);
    }
  };

  const fetchAth = async (tokenAddress) => {
    const response = await fetch(`https://phxairdrop.ytxviewers.com/api/custom/getTokenSwaps2?tokenAddress=${tokenAddress}`);
    const data = await response.json();
    return data?.ath;
  };

  useEffect(() => {
    fetchPhxPrice();

    const fetchAllAth = async () => {
      const newAthValues = {};
      for (const pair of pairs) {
        if (pair.symbol !== "wPHX") {
          newAthValues[pair.contractAddress] = await fetchAth(pair.contractAddress);
        }
      }
      setAthValues(newAthValues);
    };

    fetchAllAth();
  }, [pairs]);

  if (!pairs || pairs.length === 0) {
    return <p>Loading pairs...</p>;
  }

  return (
    <table className="chart-table-info liquidity custom-table">
      <thead>
        <tr className="chart-table-liquidity">
          <th>Pair</th>
          <th>Liquidity</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {pairs.map((pair, index) => {
          if (!pair.symbol) {
            console.error("Token symbol is undefined for pair:", pair);
            return null;
          }
          const formattedBalance = (pair.balance / 10 ** pair.decimals).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 18,
          });
          let pairPrice = pair.symbol === "wPHX" && phxPrice ? (phxPrice * (pair.balance / 10 ** pair.decimals)).toFixed(3) : pair.value?.toFixed(3);

          return (
            <tr key={index} className={`chart-table-liq`}>
              <td className="pair-cell">
                <a
                  href={UtilsToken.getExplorerLinkContract(
                    EnumChainId.PHX,
                    pair.contractAddress
                  )}
                  target="_blank"
                >
                  {pair.symbol}
                </a>
              </td>
              <td className="liquidity-cell">
                <span className="liquidity-value">{formattedBalance}</span>
              </td>
              <td className="price-cell">
                <span className="price-value">
                  {pair.symbol === "wPHX" ? `$${pairPrice}` : pairPrice}
                </span>
              </td>
              <td className="ath-cell">
                {pair.symbol !== "wPHX" && athValues[pair.contractAddress] && (
                  <span className="ath-value">
                   ATH:{athValues[pair.contractAddress]}
                  </span>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ChartTokenPairs;
