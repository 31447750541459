import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

//fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
//import UtilsToken from "../../utils/token";

const SearchResults = ({ onTokenSelected, setSearch, isOpenLink, setShowResult, showResult, results, onResultClick, onAddressChange }) => {
  const ref = useRef();

  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        return;
      }
      setShowResult(false);
      setSearch("");
    });
  }, []);

  return (
    <div
      className={
        showResult && results && results.length
          ? "dropdown-search open"
          : "dropdown-search"
      }
      ref={ref}
    >
      <div className="results">
        <div className="results-list">
          <div className="results-text">
            <p className="result-p">Results 🔥</p>
            <span className="close-icon" onClick={() => setSearch("")}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
          </div>
          <ul>
            {results
              ? results.map((el, index) => {
                function getElemContent() {
                  const cleanAddress = el.address.replace(/[\s\S]*(0x[0-9a-fA-F]{40})[\s\S]*/g, '$1'); // Extract the contract address
                  return (
                    <React.Fragment key={index}>
                      <Link
                        to={`/tokens/${cleanAddress}`} // Update the dynamic part of the URL
                        className="result-item"
                        onClick={() => {
                          if (onResultClick) {
                            onResultClick(cleanAddress);
                            console.log("Selected token: ", cleanAddress);
                          }
                          setShowResult(false); // Close the search results dropdown
                          onTokenSelected(); // Call the new callback function
                        }}
                      >
                        {/* <img src={`${process.env.REACT_APP_VOTING_SERVER_URL}/image/${el.logo}`} alt=""/> */}
                        <div>
                          <p>{el.name || "Title"}</p>
                          <span>{el.symbol || "Symbol"}</span>
                        </div>
                      </Link>
                    </React.Fragment>
                  );
                }
                  return <li key={index}>{getElemContent()}</li>;
                })
              : ""}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;