import React from "react";
import { Link } from "react-router-dom";

import "./featuredtokens.css";

const tokens = [
  { name: "TrustLinker", url: "https://firestools.com/#/tokens/0xa1647b52364ceafb06810c5e07a23a5be5006703" },
  { name: "YTXViewer", url: "https://firestools.com/#/tokens/0xa44c072aa2b5392766aaf5b86e4b78d2d10dff1a" },
  { name: "Governance Phoenix", url: "https://firestools.com/#/tokens/0x163E8183446580D5f48bd25eDeE132dFED9D60c9" },
  { name: "Elmo", url: "https://firestools.com/#/tokens/0x2e6f325717639c260d434a8e3035508dcfd5536b" },
  { name: "CFG Ninja", url: "https://firestools.com/#/tokens/0x5ca60c5e0df639fa827ce9834e05cd42d095b0eb" },
  { name: "Diamond", url: "https://firestools.com/#/tokens/0xcec504ad8b2852bd6193458f05e04896c57989f5" },
  { name: "Preperation-H", url: "https://firestools.com/#/tokens/0xff35b8cd283ca9cc181267b657cd02b456470e44" },
  { name: "MetaTec", url: "https://firestools.com/#/tokens/0xd0eca7dd6055bba7b7eab721baa20e8e1083c98f" },
  { name: "Zebrocoin", url: "https://firestools.com/#/tokens/0x89c4434119b237922069673439c35f90025563c9" },
  { name: "Emerald", url: "https://firestools.com/#/tokens/0xcd03ad1dd04acb1e980c816d125e628237ab8cc6" },

];

const FeaturedTokens = ({ chart_featured, featured_txt, featured_list }) => {
    return (
      <div className="featured-area">
        <div className={!chart_featured ? "featured-list" : "featured-list-chart"}>
          {!chart_featured ? (
            <p className="featured-txt">{featured_txt}</p>
          ) : (
            <a className="featured-txt-link" href="/"  rel="noreferrer">
              {featured_txt}
            </a>
          )}
  
          <div className="scroll-container">
            <ul>
              {tokens.map((token, index) => {
                // Check if the URL is an external link (starts with 'http' or 'https')
                const isExternal = /^(https?:)?\/\//.test(token.url);
  
                if (isExternal) {
                  return (
                    <a href={token.url} key={index} rel="noopener noreferrer">
                      <li>
                        <span className={!chart_featured ? "text-black" : "text-black-chart"}>
                          #{index + 1} <span className="text-red">{token.name}</span>
                        </span>
                      </li>
                    </a>
                  );
                } else {
                  return (
                    <Link to={token.url} key={index}>
                      <li>
                        <span className={!chart_featured ? "text-black" : "text-black-chart"}>
                          #{index + 1} <span className="text-red">{token.name}</span>
                        </span>
                      </li>
                    </Link>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  
  export default FeaturedTokens;
