import React, { useContext, useEffect, useState } from "react";

// context
import ChartInfoContext from "../../../store/chartInfo-context";
import ChartTokenPairs from "./ChartTokenPairs";

// components
import ChartTokenTx from "./ChartTokenTx";

const ChartInfoBody = ({ transactions, pairs, contract, price, address }) => {
  const bodyContentInfo = useContext(ChartInfoContext);
  console.log("Pairs data in ChartInfoBody:", pairs);
  const [fetchedTransactions, setFetchedTransactions] = useState([]);

  const fetchTransactions = async (address) => {
    try {
      const response = await fetch(
        `https://phxairdrop.ytxviewers.com/api/custom/getTokenSwaps?tokenAddress=${address}`
      );
      const data = await response.json();
      setFetchedTransactions(data);
      console.log("Fetched transactions:", data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    fetchTransactions(address);
  }, [address]);

  console.log("fetchedTransactions state:", fetchedTransactions);

  return (
    <div className="chart-info-body">
      <div className={`${bodyContentInfo.activeContent("token-tx")}`}>
        {fetchedTransactions.length > 0 ? (
          <ChartTokenTx
            transactions={fetchedTransactions}
            price={price}
            address={address}
          />
        ) : (
          <p>Loading transactions...</p>
        )}
      </div>
  
      <div
        style={{ color: "var(--white)" }}
        className={`${bodyContentInfo.activeContent("liquidity-info")}`}
      >
        <ChartTokenPairs pairs={pairs} />
      </div>
    </div>
  );
};

export default ChartInfoBody;