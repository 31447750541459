import React from "react";
//img

const ChartChtr = ({chtr_text, chtr_link}) => {
    return (
        <a className="chart-chtr" href={chtr_link} target="_blank" rel="noreferrer">
            <img className="bsc-img" src='https://fireswap.org/static/media/PHX.286b4d26.svg' alt=""/>
            <span>{ chtr_text }</span>
        </a>
    )
}

export default ChartChtr;